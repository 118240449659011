import api from "./api";

export const deleteEquipment = async (id) => {
    try {
        const equipment = await api.delete(`/machines/${id}`);

        return (equipment.status === 200) ? equipment : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}