import api from './api';

async function HandleLoginWithPassword(login, password) {
  const response = await api
    .post('/login', {
      email: login,
      password: password,
      remember: false,
    })
    .catch(() => { return false })
    .finally(() => { return false })
  return response

}

async function HandleLogout() {  
  const response = await api
    .post('/logout')
    .catch(() => { return false })
    .finally(() => { return false })

  return response
}

export {
  HandleLogout, HandleLoginWithPassword
}