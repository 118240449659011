import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BiLogOut, BiCog, BiFile } from "react-icons/bi";
import { IoIosCreate } from "react-icons/io";
import { BsFileCheck } from "react-icons/bs";
import { HiOutlineClipboardList } from "react-icons/hi";
import AuthContext from './../../context/AuthContext';
import ModalContext from './../../context/ModalContext';
import LoadingModal from './../../components/modals/LoadingModal';
import './.././style.css';

const menuButtons = [
  {
    name: 'Ordem de Serviço',
    description: 'Executar uma ordem de serviço',
    route: '/maintenanceflow?type=order-service',
    icon: <HiOutlineClipboardList size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />,
  },
  {
    name: 'Equipamentos',
    description: 'Listagem de equipamentos',
    route: 'equipments',
    icon: <BiCog size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />,
  },
  {
    name: 'Serviços Executados',
    description: 'Listagem de serviços executados',
    route: 'services',
    icon: <BiFile size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />,
  },
  /*{
    name: 'Diário de Atividades',
    description: 'Lançar atividades',
    route: '/maintenanceflow?type=daily-activities',
    icon: <BiFile size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />,
  },
  {
    name: 'Acompanhamento de Projetos',
    description: 'Lançar atividades',
    route: '/maintenanceflow?type=follow-up-project',
    icon: <HiOutlineClipboardList size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />,
  },*/
]

export default function Menu () {
  const { logOut } = useContext(AuthContext);
  const { setLoadingModalOpen } = useContext(ModalContext);
  const navigate = useNavigate();
    
  async function handleLogout(e) {
    setLoadingModalOpen(true)
    e.preventDefault()
    await logOut().then(() => setLoadingModalOpen(false));
    navigate('/login');    
  }

  return (
    <article className='h-[calc(100%-3rem)] rounded-md bg-gray-100 w-full mx-4 shadow-lg max-w-[420px] md:max-w-screen-sm sm:mx-auto xl:max-w-screen-lg border-primary-color p-3 z-10 overflow-hidden overflow-y-auto menu-section'>
      <LoadingModal />
      <section className='m-h-full grid grid-cols-2 w-full p-3 gap-6 md:grid-cols-3 xl:grid-cols-4 rounded-md overflow-auto menu-section'>
        {
          menuButtons.map((item, index) => (
            <Link to={ item.route } key={index}>
              <div className='rounded-md text-primary-color text-center shadow-sm hover:shadow-md hover:bg-primary-color hover:text-white duration-150 hover:cursor-pointer flex flex-col flex-wrap items-center justify-center hover:border-primary-color bg-gray-200 w-full hover:scale-105 py-5 aspect-square px-3'>
                { item.icon }
                <h4 className='font-bold text-sm xl:text-lg'>{ item.name }</h4>
                <p className='hidden text-xs md:inline px-2 xl:text-[11px]'>{ item.description }</p>
              </div>
            </Link>
          ))
        }
        <div onClick={(e) => handleLogout(e)} className='rounded-md text-primary-color text-center shadow-sm hover:shadow-md hover:bg-primary-color hover:text-white duration-150 hover:cursor-pointer flex flex-col flex-wrap items-center justify-center hover:border-primary-color bg-gray-200 w-full hover:scale-105 py-5 aspect-square px-3'>
          <BiLogOut size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />
          <h4 className='font-bold text-sm xl:text-lg'>Logout</h4>
          <p className='hidden text-xs md:inline px-2 xl:text-[11px]'>Sair da plataforma</p>
        </div>
      </section>
    </article>
  )
}