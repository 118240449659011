import { createContext } from 'react';

const AuthContext = createContext(
  { 
    signed: true,
    loading: true,
    user: null,
    token: null,
    logIn: null,
    logOut: null
  }
);

export default AuthContext;