import { React, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from './../../../components/layout/Header';
import Footer from './../../../components/layout/Footer';
import { getClients } from '../../../services/getClients';
import './style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getEquipment } from '../../../services/getEquipment';
import { updatedEquipment } from '../../../services/updateEquipment';

export default function EditEquipment() {

    const { id } = useParams();
    const navigate = useNavigate();
    const [clients, setClients] = useState([]);
    const [id_client, setIdClient] = useState(null);
    const [equipment, setEquipment] = useState({
        nome: "",
        identificacao: "",
        localizacao: "",
        id_cliente: "",
        fabricante: "",
        marca: "",
        modelo: "",
        potencia: "",
        numero_serie: "",
    });

    const notifySuccess = () => toast.success("Equipamento salvo com sucesso !");
    const notifyError = () => toast.error("Erro ao salvar equipamento !");

    function navigateToList() {
        navigate("/equipments");
    }

    function handlerSuccessEquipment() {
        notifySuccess();
        setTimeout(() => {
            navigate("/equipments");
        }, 1000);
    }

    useEffect(() => {
        const fetchData = async () => {
            const responseClients = await getClients();

            if (!responseClients) {
                setClients([]);
                return
            }

            setClients(responseClients.data);

            const responseEquipment = await getEquipment(id);

            setEquipment(responseEquipment.data);
            setIdClient(responseClients.data.id_cliente);
        }

        fetchData();
    }, [])

    function handleChange(e) {
        const { name, value } = e.target;

        setEquipment({
            ...equipment,
            [name]: value
        });
    }

    function handlerChangesClient(e) {
        var value = e.target.value;

        setIdClient(value);
    }

    async function handlerUpdateEquipment() {

        const response = await updatedEquipment(id, equipment);

        response ? handlerSuccessEquipment() : notifyError();
    }

    return (
        <section className="h-screen h-[calc(var(--vh)*100)] bg-gray-200 w-full text-white">
            <Header />
            <div className='w-full h-[calc(100%-7rem)] -mt-[3rem] flex clients-start justify-center relative'>
                <article className='grid-mobile h-[calc(100%-3rem)] z-10 menu rounded-md bg-gray-100 w-full mx-4 shadow-lg max-w-[420px] md:max-w-screen-sm sm:mx-auto xl:max-w-screen-lg p-3 overflow-hidden'>
                    <div>
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-primary-color text-2xl font-bold mb-4">Editar Equipamento</h1>
                            </div>
                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                <button onClick={() => navigateToList()} className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-color px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-color focus:outline-none focus:ring-2 focus:ring-primary-color focus:ring-offset-2 sm:w-auto">
                                    Listar
                                </button>
                            </div>
                        </div>
                        <div>
                            <div className="mt-6 grid grid-cols-1 sm:grid-cols-12 gap-y-6 gap-x-4">
                                <div className="sm:col-span-12">
                                    <label className="block text-sm font-medium text-gray-700">Nome</label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="nome"
                                            value={equipment.nome}
                                            onChange={handleChange}
                                            className="text-black shadow-sm focus:ring-primary-color focus:border-primary-color block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6 grid grid-cols-2 sm:grid-cols-12 gap-y-6 gap-x-4">
                                <div className="sm:col-span-3">
                                    <label className="block text-sm font-medium text-gray-700">Identificação</label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="identificacao"
                                            value={equipment.identificacao}
                                            onChange={handleChange}
                                            className="text-black shadow-sm focus:ring-primary-color focus:border-primary-color block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label className="block text-sm font-medium text-gray-700">Localização</label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="localizacao"
                                            value={equipment.localizacao}
                                            onChange={handleChange}
                                            className="text-black shadow-sm focus:ring-primary-color focus:border-primary-color block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-6">
                                    <label className="block text-sm font-medium text-gray-700">Proprietário</label>
                                    <div className="mt-1">
                                        <select value={id_client} onChange={handlerChangesClient} className="text-black shadow-sm focus:ring-primary-color focus:border-primary-color block w-full sm:text-sm border-gray-300 rounded-md">
                                            <option value="Selecione o cliente">Selecione o cliente</option>
                                            {
                                                clients.map((client) => (
                                                    <option key={client.id} value={client.id}>{client?.cnpj ? `${client?.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')} - ${client?.nome_fantasia}` : ''}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="sm:col-span-4">
                                    <label className="block text-sm font-medium text-gray-700">Fabricante</label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="fabricante"
                                            value={equipment.fabricante}
                                            onChange={handleChange}
                                            className="text-black shadow-sm focus:ring-primary-color focus:border-primary-color block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-4">
                                    <label className="block text-sm font-medium text-gray-700">Marca</label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="marca"
                                            value={equipment.marca}
                                            onChange={handleChange}
                                            className="text-black shadow-sm focus:ring-primary-color focus:border-primary-color block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-4">
                                    <label className="block text-sm font-medium text-gray-700">Modelo</label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="modelo"
                                            value={equipment.modelo}
                                            onChange={handleChange}
                                            className="text-black shadow-sm focus:ring-primary-color focus:border-primary-color block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-4">
                                    <label className="block text-sm font-medium text-gray-700">Potência</label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="potencia"
                                            value={equipment.potencia}
                                            onChange={handleChange}
                                            className="text-black shadow-sm focus:ring-primary-color focus:border-primary-color block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-4">
                                    <label className="block text-sm font-medium text-gray-700">Número de Série</label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="numero_serie"
                                            value={equipment.numero_serie}
                                            onChange={handleChange}
                                            className="text-black shadow-sm focus:ring-primary-color focus:border-primary-color block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="sticky bottom-0 left-0 w-full bg-gray-100">
                                <div className="mt-6 flex justify-end">
                                    <button onClick={() => navigate("/equipments")} className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color">
                                        Cancelar
                                    </button>
                                    <button disabled={id_client !== "Selecione o cliente" ? false : true} onClick={handlerUpdateEquipment} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-color hover:bg-primary-color focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color ml-7">
                                        Salvar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
            <ToastContainer />
            <Footer />
        </section>
    )
}