import api from "./api";

export const getServicesPerfomed = async (startDate, endDate) => {
    try {
        const services = await api.get(`/maintenance/order-service`, {
            params: {
                startDate: startDate || null,
                endDate: endDate || null,
            },
        });

        return (services.status === 200) ? services : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}