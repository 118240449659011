import React, { useState } from 'react';
import MainContext from './MainContext';

function MainProvider({ children }) {
  // EQUIPAMENTO
  const [equipamentArray, setEquipamentArray] = useState([]);
  const [selectedEquipament, setSelectedEquipament] = useState(null);

  // FLUXO
  const [flowArray, setFlowArray] = useState([]);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [answersArray, setAnswersArray] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(null);
  const [reloadedFlow, setReloadedFlow] = useState(null);

  // ANSWER
  const date = new Date();
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

  const [datetimeValue, setDatetimeValue] = useState(date.toISOString().slice(0, 16));
  const [dateValue, setDateValue] = useState(date.toISOString().slice(0, 10));
  const [timeValue, setTimeValue] = useState(date.toISOString().slice(11, 16));
  const [textValue, setTextValue] = useState('');
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [checkedRadio, setCheckedRadio] = useState('');
  const [otherRadioValue, setOtherRadioValue] = useState('');
  const [signatureValue, setSignatureValue] = useState('');
  const [filesSavedArray, setFilesSavedArray] = useState([]);

  function handleResetValues () {
    setDatetimeValue(date.toISOString().slice(0, 16));
    setDateValue(date.toISOString().slice(0, 10));
    setTimeValue(date.toISOString().slice(11, 16));
    setTextValue('');
    setCheckedBoxes([]);
    setCheckedRadio('');
    setOtherRadioValue('');
    setSignatureValue('');
    setFilesSavedArray([]);
  }

  //MODAL
  const [flowOnline, setFlowOnline] = useState(null);
  const [downloadButton, setDownloadButton] = useState(true);
  const [isFlowComplete, setIsFlowComplete] = useState(false);

  const [observationsArray, setObservationsArray] = useState([]);
  const [filesArray, setFilesArray] = useState([]);

  const context = {
    equipamentArray, setEquipamentArray,
    selectedEquipament, setSelectedEquipament,
    flowArray, setFlowArray,
    selectedFlow, setSelectedFlow,
    selectedQuestion, setSelectedQuestion,
    answersArray, setAnswersArray,
    selectedAnswer, setSelectedAnswer,
    questionIndex, setQuestionIndex,
    reloadedFlow, setReloadedFlow,
    //answers
    datetimeValue, setDatetimeValue,
    dateValue, setDateValue,
    timeValue, setTimeValue,
    textValue, setTextValue,
    checkedBoxes, setCheckedBoxes,
    checkedRadio, setCheckedRadio,
    otherRadioValue, setOtherRadioValue,
    signatureValue, setSignatureValue,
    filesSavedArray, setFilesSavedArray,
    //func
    handleResetValues,
    //modal
    flowOnline, setFlowOnline,
    downloadButton, setDownloadButton,
    isFlowComplete, setIsFlowComplete,
    observationsArray, setObservationsArray,
    filesArray, setFilesArray,
  };

  return (
    <MainContext.Provider value={ context }>
      {children}
    </MainContext.Provider>
  )
}

export default MainProvider;