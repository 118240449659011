import React from 'react';
import { Link } from 'react-router-dom';
// import Logo from './../assets/logo/Logo Final.png'

export default function Example() {
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          <div className="flex flex-shrink-0 justify-center">
            <a href="/" className="inline-flex">
              {/* <img
                className="h-36 w-auto"
                src={ Logo }
                alt=""
              /> */}
            </a>
          </div>
          <div className="py-10">
            <div className="text-center">
              <p className="text-base font-semibold text-primary-color">:(</p>
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Página não encontrada</h1>
              <p className="mt-2 text-base text-gray-500">Pedimos desculpas, mas não encontramos a página que você está procurando...</p>
              <div className="mt-6">
                <Link to="/" className="text-base font-medium text-primary-color hover:text-secondary-color">
                  Voltar para a página inicial
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
