import { Fragment, useContext, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ModalContext from './../../context/ModalContext';
import MainContext from './../../context/MainContext';

export default function ReloadFlowModal() {    
  const { reloadFlowModalOpen, setReloadFlowModalOpen } = useContext(ModalContext);
  const { setReloadedFlow } = useContext(MainContext);

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={reloadFlowModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setReloadFlowModalOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="text-center">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Essa ordem de serviço já foi iniciada anteriormente<br />
                      O que deseja fazer?
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-5 flex flex-row items-center justify-between px-2">
                  <button
                    type="button"
                    className="h-[3rem] items-center text-center inline-flex w-2/3 justify-center rounded-md border-2 border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm opacity-80 hover:opacity-100 sm:col-start-1 sm:mt-0 sm:text-sm duration-150"
                    onClick={() => {
                      setReloadedFlow(false)
                      setReloadFlowModalOpen(false)
                    }}
                    ref={cancelButtonRef}
                    >
                    Recomeçar
                  </button>
                  <button
                    type="button"
                    className="h-[3rem] items-center text-center inline-flex w-2/3 justify-center rounded-md border-2 border-primary-color bg-white px-4 py-2 text-base font-medium text-primary-color shadow-sm hover:bg-primary-color hover:text-white sm:col-start-2 sm:text-sm duration-150 mx-2"
                    onClick={() => {
                      setReloadedFlow(true)
                      setReloadFlowModalOpen(false)      
                    }}
                  >
                    Continuar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
