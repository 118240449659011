import React from 'react';
import { useContext, useEffect } from 'react';
import MainContext from './../../../context/MainContext';

export default function TextAnswer ({ question, answer }) {
  const { textValue, setTextValue, setFilesSavedArray } = useContext(MainContext);

  useEffect(() => {
    handleSet();
  }, [question]);

  function handleSet() {
    if (!answer || !answer.resposta) {
      return;
    }

    answer.anexo.forEach(file => {
      setFilesSavedArray(files => [...files, file]);
    });

    setTextValue(answer.resposta);
  }

  function handleChange (event) {
    setTextValue(event.target.value)
  }
  
  return (
    <>
      <textarea
        value={textValue}
        onChange={(event) => handleChange(event)}
        key={`${question.ordem}-${question.id}`} 
        name={`${question.ordem}-${question.id}`}
        className='w-full h-[calc(100%-2.5rem)] mt-[1rem] mx-auto rounded-md border-gray-400 focus:ring-0 focus:ring-offset-0 grow focus:outline-none focus:border-primary-colo focus:ring-primary-color focus:ring-1'
      />    
    </>
  )
}