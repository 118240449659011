import api from "./api";

export const getClients = async () => {
    try {
        const clients = await api.get("/clients");

        return (clients.status === 200) ? clients : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}