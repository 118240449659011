import React from 'react'

export default function Footer () {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer className='w-full h-[3rem] -mt-[3rem] text-black text-xs md:text-md flex justify-center items-center'>
      © { year } - Desenvolvido por&nbsp;<a className='text-primary-color hover:text-secondary-color duration-150 ease-in-out' href="https://agatecnologia.com/" target='_blank' rel="noreferrer"><strong>AGA</strong> Tecnologia</a>
    </footer>
  )
}