import React, { useContext, useEffect } from 'react'
import MainContext from './../../../context/MainContext';

export default function TimeAnswer ({ question, answer }) {
  const { timeValue, setTimeValue, setFilesSavedArray } = useContext(MainContext);

  useEffect(() => {
    handleSet();
  }, [question]);

  function handleSet() {
    if (!answer || !answer.resposta) {
      return;
    }

    answer.anexo.forEach(file => {
      setFilesSavedArray(files => [...files, file]);
    });

    setTimeValue(answer.resposta);
  }

  return (
    <div className='flex w-full flex-col items-center justify-center'>
      <input
        className='h-[3rem] w-[250px] border-gray-400 rounded-md my-4 text-xl md:text-base'
        type="time"
        value={timeValue}
        key={`${question.ordem}-${question.id}`} 
        name={`${question.ordem}-${question.id}`}
        onChange={(event) => setTimeValue(event.target.value)}
      />
    </div>
  )
}