import React, { useRef, useEffect } from 'react';
import { useContext } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import MainContext from './../../../context/MainContext';
import { RxReload } from 'react-icons/rx';

export default function SignatureAnswer ({ question, answer }) {
  const { setSignatureValue, setFilesSavedArray } = useContext(MainContext);

  const ref = useRef();

  useEffect(() => {
    handleBegin();
  }, [question]);

  function handleBegin() {
    if (!ref) {
      return;
    }
    
    if (!answer || !answer.resposta) {
      return;
    }

    answer.anexo.forEach(file => {
      setFilesSavedArray(files => [...files, file]);
    });

    ref.current.fromDataURL(answer.resposta);
    setSignatureValue(answer.resposta);
  }

  function handleChange() {
    if (!ref) {
      return;
    }

    setSignatureValue(ref.current.toDataURL('image/png'));
  }
  
  return (
    <div className='flex w-full flex-col items-center justify-center'>
      <SignatureCanvas 
        key={`${question.ordem}-${question.id}`} 
        name={`${question.ordem}-${question.id}`}
        onEnd={ handleChange }
        ref={ ref }
        penColor='#000000'
        maxWidth={1}
        minWidth={0.2}
        canvasProps={{height: '260px', className: 'w-full bg-white border border-gray-500'}} />
      <aside className='text-center mx-auto items-center w-full py-4 flex w-full justify-end'> 
        <RxReload 
          className='text-primary-color text-4xl p-1 mx-4 hover:scale-110 duration-150' 
          onClick={() => {
            setSignatureValue('');
            ref.current.clear();
          }}
        />
      </aside>
    </div>
  )
}