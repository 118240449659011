import { useContext } from "react";
import AuthContext from "./context/AuthContext";

const ProtectedRoutes = ({ children }) => {
  const { signed } = useContext(AuthContext);

  if (signed) {
    return children
  }
}

export default ProtectedRoutes;