import { React, useEffect, useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Header from './../../../components/layout/Header';
import Footer from './../../../components/layout/Footer';
import { BsFillTrash3Fill, BsPencil, BsArrowLeft } from "react-icons/bs";
import './style.css';
import ReactPaginate from 'react-paginate';
import { getEquipments } from '../../../services/getEquipments';
import { deleteEquipment } from '../../../services/deleteEquipment';
import LoadingModal from './../../../components/modals/LoadingModal';
import ModalContext from './../../../context/ModalContext';

export default function ListEquipment() {

    const [equipments, setEquipments] = useState([]);
    const [filteredEquipments, setFilteredEquipments] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(0);
    const equipmentsPerPage = 5;
    const navigate = useNavigate();

    const {
        setLoadingModalOpen,
    } = useContext(ModalContext);

    const fetchData = async () => {
        setLoadingModalOpen(true);

        const responseEquipments = await getEquipments();

        if (!responseEquipments) {
            setLoadingModalOpen(false);
            setEquipments([]);

            return
        }

        setEquipments(responseEquipments.data);
        setFilteredEquipments(responseEquipments.data);
        setLoadingModalOpen(false);
    }

    function navigateToCreate() {
        navigate("/equipment");
    }

    function navigateToEdit(id) {
        navigate(`/equipment/${id}`);
    }

    async function deleteEquipmentFunc(id) {
        setLoadingModalOpen(true);

        const response = await deleteEquipment(id);

        if (response !== false) {
            fetchData();
        }

        setLoadingModalOpen(false);
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        if (!searchQuery) {
            setFilteredEquipments(equipments);
        } else {
            const filteredEquipments = equipments.filter(equipment =>
                equipment.nome.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredEquipments(filteredEquipments);
        }
    }, [searchQuery, equipments]);

    const renderEquipments = () => {
        const offset = pageNumber * equipmentsPerPage;
        const currentEquipments = filteredEquipments.slice(offset, offset + equipmentsPerPage);

        return filteredEquipments.map((equipment) => (
            <tr key={equipment.id}>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
                    <button onClick={() => navigateToEdit(equipment.id)} type="button" className="inline-flex items-center p-1 border border-transparent rounded-l shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        <BsPencil className='h-4 w-4' />
                    </button>
                    <button onClick={() => deleteEquipmentFunc(equipment.id)} type="button" className="inline-flex items-center p-1 border border-transparent rounded-r shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                        <BsFillTrash3Fill className='h-4 w-4' />
                    </button>
                </td>
                <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm">
                    <div className="font-medium text-gray-500">{equipment.identificacao}</div>
                </td>
                <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">
                    <div className="text-gray-500">{equipment.nome}</div>
                </td>
                <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">
                    <div className="text-gray-500">{equipment?.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')} - {equipment?.nome_fantasia}</div>
                </td>
                <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">
                    <div className="text-gray-500">{equipment.numero_serie}</div>
                </td>
            </tr>
        ));
    };

    const handlePageClick = (data) => {
        setPageNumber(data.selected);
    };

    return (
        <section className="h-screen h-[calc(var(--vh)*100)] bg-gray-200 w-full text-white">
            <LoadingModal />
            <Header />
            <div className='w-full h-[calc(100%-7rem)] -mt-[3rem] flex items-start justify-center relative'>
                <article className='h-[calc(100%-3rem)] z-10 menu rounded-md bg-gray-100 w-full mx-4 shadow-lg max-w-[420px] md:max-w-screen-sm sm:mx-auto xl:max-w-screen-lg p-3 overflow-auto'>
                    <div className='h-[4rem] border-primary-color border-b-2 p-2 flex flex-row items-center justify-between'>
                        <div>
                            <h2 className='text-primary-color font-bold text-xl pt-2'>Equipamentos</h2>
                            <h2 className='text-black text-xs pb-2'>Listagem de equipamentos</h2>
                        </div>
                        <button onClick={() => navigateToCreate()} className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-color px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-color focus:outline-none focus:ring-2 focus:ring-primary-color focus:ring-offset-2 sm:w-auto">
                            Adicionar
                        </button>
                        <Link to='/'>
                            <BsArrowLeft className='text-primary-color text-4xl p-1 hover:scale-110 duration-150 hover:bg-primary-color hover:text-white rounded-full'/>        
                        </Link>
                    </div>
                
                    <div className="mt-3 overflow-x-auto max-w-7xl mx-auto">
                        <div className="inline-block min-w-full py-2 align-middle">
                            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"></th>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900">Identificação</th>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900">Nome</th>
                                            <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Proprietário</th>
                                            <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Número de Série</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {renderEquipments()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        <Footer />
    </section>
    )
}