import React from 'react';
import './style.css';
import logo from './logo.png';

const Logo = ({ className }) => {
  return (
    <img src={ logo } className={ `${className} p-3` } />
  );
}

const FullWhiteLogo = ({ className }) => {
  return (
    <img src={ logo } className={ `${className} p-3` } />
  );
}

const WhiteLogo = ({ className }) => {
  return (
    <img src={ logo } className={ `${className} p-3` } />
  );
}

const TextlessLogo = ({ className }) => {
  return (
    <img src={ logo } className={ `${className} p-3` } />
  );
}

export {
  Logo, TextlessLogo, WhiteLogo, FullWhiteLogo
}