import React, { useContext, useEffect } from 'react'
import MainContext from './../../../context/MainContext';

export default function DateAnswer ({ question, answer }) {
  const { dateValue, setDateValue, setFilesSavedArray } = useContext(MainContext);

  useEffect(() => {
    handleSet();
  }, [question]);

  function handleSet() {
    if (!answer || !answer.resposta) {
      return;
    }

    answer.anexo.forEach(file => {
      setFilesSavedArray(files => [...files, file]);
    });

    setDateValue(answer.resposta);
  }

  return (
    <div className='flex w-full flex-col items-center justify-center'>
      <input
        className='h-[3rem] w-[250px] border-gray-400 rounded-md my-4 text-xl md:text-base'
        type="date"
        value={dateValue}
        key={`${question.ordem}-${question.id}`} 
        name={`${question.ordem}-${question.id}`}
        onChange={(event) => setDateValue(event.target.value)}
      />
    </div>
  )
}