import Routing from "./Routes";
import MainProvider from './context/MainProvider';
import ModalProvider from './context/ModalProvider';
import AuthProvider from "./context/AuthProvider";

function App() {
  return (
    <AuthProvider>
      <MainProvider>
        <ModalProvider>
          <Routing />
        </ModalProvider>
      </MainProvider>
    </AuthProvider>
  );
}

export default App;
