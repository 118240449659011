import api from "./api";

export const getEquipments = async () => {
    try {
        const equipment = await api.get("/machines");

        return (equipment.status === 200) ? equipment : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}