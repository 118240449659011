import { React, useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowLeft } from "react-icons/bs";
import DatePicker from "react-datepicker";
import Header from './../../components/layout/Header';
import Footer from './../../components/layout/Footer';
import ReactPaginate from 'react-paginate';
import './style.css';
import "react-datepicker/dist/react-datepicker.css";
import { getServicesPerfomed } from '../../services/getServicesPerformed';
import api from './../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import LoadingModal from './../../components/modals/LoadingModal';
import ModalContext from './../../context/ModalContext';

export default function ListingServicesPerformed() {

    const [services, setServices] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const servicesPerPage = 5;
    const navigate = useNavigate();

    const {
        setLoadingModalOpen,
    } = useContext(ModalContext);

    const notifySuccess = () => toast.success('Relatório em Processamento');
    const notifyError = () => toast.error('Erro ao baixar relatório');

    function dateFormat(date) {
        const partsDate = date.toLocaleDateString('pt-br', { timeZone: 'America/Sao_Paulo' }).split('T')[0].split("/");

        const day = partsDate[0];
        const month = partsDate[1];
        const year = partsDate[2];

        return `${year}/${month}/${day}`;
    }

    const fetchData = async () => {
        setLoadingModalOpen(true);

        const responseServices = await getServicesPerfomed();

        if (!responseServices) {
            setLoadingModalOpen(false);
            setServices([]);

            return
        }

        setServices(responseServices.data);
        setLoadingModalOpen(false);
    }

    useEffect(() => {
        fetchData();
    }, [])

    async function removefilterMaintenance() {
        fetchData();
        setStartDate(null);
        setEndDate(null);
    }

    async function filterMaintenance() {
        const responseServices = await getServicesPerfomed(
            startDate !== null ? dateFormat(startDate) : startDate,
            endDate !== null ? dateFormat(endDate) : endDate
        );

        if (!responseServices) {
            setServices([]);
            return
        }

        setServices(responseServices.data);
    }

    async function reportDownload(idEquipamento, numeroExecucao, idFluxo) {
        notifySuccess();

        api.post('report/download/order-service', {
                id_equipamento: idEquipamento,
                numero_execucao: numeroExecucao,
                id_fluxo: idFluxo,
            }, {
                responseType: 'blob',
            }).then(({ data }) => {
                let blob = new Blob([data], { type: 'application/pdf' }),
                url = window.URL.createObjectURL(blob)
                window.open(url)
            }).catch((err) => {
                notifyError();

                console.log(err);
            });
    }

    const renderServices = () => {
        const offset = pageNumber * servicesPerPage;
        const currentServices = services.slice(offset, offset + servicesPerPage);

        return services.map((service) => (
            <tr key={service.id}>
                <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm">
                    <div className="font-medium text-gray-900">
                        {service.identificacao_cliente}
                    </div>
                    <div className="font-medium text-gray-500">
                        {service?.cnpj_cliente ? `${service?.cnpj_cliente?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}` : ''}
                    </div>
                </td>
                <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm">
                    <div className="font-medium text-gray-900">
                        {service.identificacao_equipamento}
                    </div>
                    <div className="font-medium text-gray-500">
                        {service.nome_equipamento}
                    </div>
                </td>
                <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm">
                    <div className="font-medium text-gray-900">
                        {service.nome_fluxo}
                    </div>
                </td>
                <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm">
                    <div className="font-medium text-gray-900">
                        {new Date(service.created_at).toLocaleDateString('pt-br', { timeZone: 'America/Sao_Paulo' })}
                    </div>
                </td>
                <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm">
                    <div className="font-medium text-gray-900">
                        {service.numero_execucao_formatado}
                    </div>
                </td>
                <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm">
                    <button onClick={() => reportDownload(service.id_equipamento, service.numero_execucao, service.id_fluxo)} type="button" className="inline-flex items-center p-1 border border-transparent rounded shadow-sm text-white bg-gray-400 hover:bg-gray-700 focus:outline-none">
                        Download
                    </button>
                </td>
            </tr>
        ));
    };

    const handlePageClick = (data) => {
        setPageNumber(data.selected);
    };

    return (
        <section className="h-screen h-[calc(var(--vh)*100)] bg-gray-200 w-full text-white">
            <LoadingModal />
            <Header />
            <div className='w-full h-[calc(100%-7rem)] -mt-[3rem] flex items-start justify-center relative'>
                <article className='h-[calc(100%-3rem)] z-10 menu rounded-md bg-gray-100 w-full mx-4 shadow-lg max-w-[420px] md:max-w-screen-sm sm:mx-auto xl:max-w-screen-lg p-3 overflow-auto'>
                    <div className='h-[4rem] border-primary-color border-b-2 p-2 flex flex-row items-center justify-between'>
                        <div>
                            <h2 className='text-primary-color font-bold text-xl pt-2'>Serviços Executados</h2>
                            <h2 className='text-black text-xs pb-2'>Listagem dos serviços executados</h2>
                        </div>
                        <Link to='/'>
                            <BsArrowLeft className='text-primary-color text-4xl p-1 hover:scale-110 duration-150 hover:bg-primary-color hover:text-white rounded-full'/>        
                        </Link>
                    </div>
                    <div>
                        <div className="mt-4 flex rounded-md shadow-sm">
                            <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                <DatePicker
                                    placeholderText="Data inicial"
                                    dateFormat="dd/MM/yyyy"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="focus:ring-primary-color focus:border-primary-color block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                                />
                                <DatePicker
                                    placeholderText="Data Final"
                                    dateFormat="dd/MM/yyyy"
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    className="focus:ring-primary-color focus:border-primary-color block w-full rounded-none sm:text-sm border-gray-300"
                                />
                            </div>
                            <button onClick={() => { filterMaintenance() }} className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary-color focus:border-primary-color">
                                Filtrar
                            </button>
                            <button onClick={() => { removefilterMaintenance() }} className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary-color focus:border-primary-color">
                                Limpar
                            </button>
                        </div>
                    </div>
                    <div className="mt-3 overflow-x-auto overflow-y-auto max-w-7xl mx-auto">
                        <div className="inline-block min-w-full py-2 align-middle">
                            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900">Cliente</th>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900">Equipamento</th>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900">Fluxo</th>
                                            <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Data</th>
                                            <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Número Execução</th>
                                            <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Relatório</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {renderServices()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        <Footer />
        </section>
    )
}