import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoginFooter from './../components/layout/LoginFooter'
import './style.css';
import { Logo, FullWhiteLogo } from './../assets/logo/Logos';
import bg from './../assets/bg.jpg'
import { useContext } from 'react';
import AuthContext from './../context/AuthContext';
import ModalContext from './../context/ModalContext';

import LoadingModal from './../components/modals/LoadingModal';

export default function Login () {
  const {
    logIn,
  } = useContext(AuthContext);

  const { setLoadingModalOpen } = useContext(ModalContext);

  const navigate = useNavigate();

  const [inputUserEmail, setInputUserEmail] = useState('');
  const [inputUserPassword, setInputUserPassword] = useState('');
  const [authFailed, SetAuthFailed] = useState(false);

  async function handleLoginWithPassword(e) {
    setLoadingModalOpen(true);
    e.preventDefault();
    SetAuthFailed(false);
    
    const response = await logIn(inputUserEmail, inputUserPassword);
    
    setLoadingModalOpen(false);

    if (response) {
      return navigate('/')
    }
    
    SetAuthFailed(true);
  }

  return (
    <>
      {/* MOBILE */}
      <div className='md:hidden w-full h-screen h-[calc(var(--vh)*100)] bg-gradient-to-b from-primary-color to-secondary-color flex items-center justify-center'>
        <LoadingModal />
        <h2 className='text-center text-white text-base pb-4 mx-20 absolute top-4 z-30'>
          Faça login para ter acesso à página administrativa da plataforma <span className='font-medium text-platform-primary-color'>Otimize</span>
        </h2>
        <div alt="" className='absolute top-0 bg-gray-50 w-full h-screen opacity-30 z-10'/>
        <section className='p-8 px-12 w-full text-center bg-transparent z-10'>
          <FullWhiteLogo className='mx-auto w-auto max-h-[10rem] my-6' />
          <form action="submit" className='mb-4'>
            <div>
              <label htmlFor="email" className="sr-only">
                e-mail
              </label>
              <input
                id="email-mobile"
                name="email-mobile"
                type="email"
                value={ inputUserEmail }
                onChange={ (e) => setInputUserEmail(e.target.value) }
                className="block w-full rounded-md border-2 border-gray-300 py-2 px-4 placeholder-primary-gray shadow-sm"
                placeholder="Email"
              />
            </div>
            <div className='mt-4'>
              <label htmlFor="password-mobile" className="sr-only">
                senha
              </label>
              <input
                id="password-mobile"
                name="password-mobile"
                type="password"
                value={ inputUserPassword }
                onChange={ (e) => setInputUserPassword(e.target.value) }
                className="block w-full rounded-md border-2 border-gray-300 py-2 px-4 placeholder-primary-gray shadow-sm"
                placeholder="Senha"
              />
            </div>
            <button
              onClick={ (e) => handleLoginWithPassword(e) }
              className='mt-4 bg-primary-color hover:bg-white text-white hover:text-primary-color font-medium px-4 py-2 rounded-md w-full duration-150'
              >
              Entrar
            </button>
            {
              authFailed && (
                <p className='text-primary-color mt-2 text-sm'>e-mail/senha incorretos</p>
              )
            }
          </form>
        </section>
        <LoginFooter />
      </div>

      {/* DESKTOP */}
      <div className='hidden md:flex bg-gray-100 h-screen h-[calc(var(--vh)*100)] w-full flex items-center justify-center relative'>
        <LoadingModal />
        <img src={bg} alt="Foto fundo Gerador" className='absolute top-0 w-full h-screen opacity-100 z-10 object-cover'/>
        <div alt="" className='absolute top-0 bg-gradient-to-t from-primary-color to-transparent w-full h-screen opacity-90 z-20'/>
        <section className='bg-white shadow-md rounded-md p-8 px-12 max-w-[400px] text-center z-40 md:scale-75 lg:scale-100'>
          <Logo className='mx-auto w-auto max-h-[10rem] my-4' />
          <h2 className='text-gray-500 text-sm pb-4 mx-5'>
            Faça login para ter acesso à página administrativa da plataforma <span className='font-medium text-platform-primary-color'>Otimize</span>
          </h2>
          <form className='my-4'>
            <div>
              <label htmlFor="email" className="sr-only">
                e-mail
              </label>
              <input
                id="email-desktop"
                name="email-desktop"
                type="email"
                value={ inputUserEmail }
                onChange={ (e) => setInputUserEmail(e.target.value) }
                className="block w-full rounded-md border-2 border-gray-300 py-2 px-4 placeholder-primary-gray shadow-sm"
                placeholder="Email"
              />
            </div>
            <div className='my-4 relative'>
              <label htmlFor="email" className="sr-only">
                senha
              </label>
              <input
                id="password-desktop"
                name="password-desktop"
                type="password"
                value={ inputUserPassword }
                onChange={ (e) => setInputUserPassword(e.target.value) }
                className="block w-full rounded-md border-2 border-gray-300 py-2 px-4 placeholder-primary-gray shadow-sm"
                placeholder="Senha"
              />
              
            </div>
            <button
              onClick={ (e) => handleLoginWithPassword(e) }
              className='bg-white border-2 border-primary-color text-gray-700 hover:bg-primary-color hover:text-white px-4 py-2 rounded-md w-full shadow-md duration-150 font-medium mt-4'>
              Entrar
            </button>
            {
              authFailed && (
                <p className='text-primary-color text-xs mt-3'>
                  e-mail/senha incorretos
                </p>
              )
            }
          </form>
        </section>
        <LoginFooter />
      </div>
    </>
  )
}