import React from 'react';
import { useContext, useEffect } from 'react';
import MainContext from './../../../context/MainContext';

export default function CheckboxAnswer ({ question, answer }) {
  const { checkedBoxes, setCheckedBoxes, setFilesSavedArray } = useContext(MainContext);

  useEffect(() => {
    handleCheck();
  }, [question]);
  
  function handleChange(event) {
    if ( event.target.checked ) {
      setCheckedBoxes([...checkedBoxes, event.target.value])
    } else {
      setCheckedBoxes(checkedBoxes.filter(el => el !== event.target.value))
    }
  }

  function handleCheck() {
    if (!answer || !answer.resposta) {
      return;
    }

    answer.anexo.forEach(file => {
      setFilesSavedArray(files => [...files, file]);
    });

    setCheckedBoxes(answer.resposta);
  }

  return (
    <div className='flex flex-col justify-center w-full max-w-[400px] mx-auto text-left'>
      {
        question.respostas && question.respostas.map((el, index) => (
          <label htmlFor={el} key={index} className='flex items-center text-xl'>
            <input 
              checked={ checkedBoxes.includes(el) }
              type="checkbox" 
              key={`${question.ordem}-${question.id}-${index}`} 
              name={`${question.ordem}-${question.id}-${index}`} 
              value={el} 
              id={el} 
              className='mx-2 mr-4 h-[2rem] w-[2rem] my-4 rounded border-gray-300 text-primary-color focus:ring-0 focus:ring-offset-0 checkbox-flow' 
              onChange={(event) => handleChange(event)} 
            />
            {el}
          </label>
        ))
      }
    </div>
  )
    
}