import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext';
import * as auth from './../services/auth';

function AuthProvider({ children }) {  
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('otimize-token');
    const user = localStorage.getItem('otimize-user');

    setLoading(false);

    if (token && user) {
      setUser(JSON.parse(user))
      setToken(token)

      return
    }

    return navigate('/login')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function LogIn (login, password) {
    const response = await auth.HandleLoginWithPassword(login, password)

    if (response.status !== 200) {
      return false
    }

    const { token, user } = response.data;
    const { id, name, email } = user;

    setUser({
      id: id,
      name: name,
      email: email,
    })

    setToken(token)

    localStorage.setItem('otimize-token', token)
    localStorage.setItem('otimize-user', JSON.stringify({
      id: id,
      name: name,
      email: email
    }))

    return true
  }

  async function LogOut() {
    const response = await auth.HandleLogout();

    if (response.status !== 200) {
      return false
    }

    setUser(null);
    setToken(null);

    localStorage.removeItem('otimize-token');
    localStorage.removeItem('otimize-user');

    return true
  }

  // USER AUTH

  const [inputUserEmail, setInputUserEmail] = useState('');
  const [inputUserPassword, setInputUserPassword] = useState('');
  const [authFailed, SetAuthFailed] = useState(false);

  const context = {
    inputUserEmail, setInputUserEmail,
    inputUserPassword, setInputUserPassword,
    authFailed, SetAuthFailed,
    signed: Boolean(user),
    loading: loading,
    user: user,
    token: token,
    logIn: LogIn,
    logOut: LogOut
  }

  return (
    <AuthContext.Provider value={ context }>
      { children }
    </AuthContext.Provider>
  )
}

export default AuthProvider;