import api from "./api";

export const updatedEquipment = async (id, equipment) => {
    try {
        const equipmentResponse = await api.put(`/machines/${id}`, equipment);

        return (equipmentResponse.status === 200) ? equipmentResponse : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}