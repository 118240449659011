export const toBase64 = (file) => {
    return readAsDataURL(file)
        .then(makeImage)
        .then(compressImage);
}

function readAsDataURL(file) {
    if (!(file instanceof Blob)) {
        throw new TypeError('Must be a File or Blob');
    }

    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        
        reader.onload = (e) => {
            resolve(e.target.result);
        }

        reader.onerror = (e) => {
            reject(`Error reading ${file.name}: ${e.target.result}`);
        }

        reader.readAsDataURL(file);
    });
}

function makeImage(fileReaderResult) {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => {
            resolve(img);
        }
      
        img.onerror = (e) => {
            reject('Error resizing image: ', e);
        }

        img.src = fileReaderResult;
    });
}

function compressImage (img) {
    const w = img.width;
    const h = img.height;
    
    const ratioWidth = w > 1920 ? 1920 / w : 1;
    const ratioHeight = h > 1920 ? 1920 / h : 1;
    
    const ratio = Math.min(ratioWidth, ratioHeight);

    const newWidth = Math.floor(w * ratio);
    const newHeight = Math.floor(h * ratio);

    const canvas = document.createElement('canvas');

    canvas.width = newWidth;
    canvas.height = newHeight;

    const ctx = canvas.getContext('2d', {
      preserveDrawingBuffer: true
    });

    ctx.drawImage(img, 0, 0, newWidth, newHeight);

    return canvas.toDataURL('image/jpeg', 0.5);
}
