import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';

// componentes de página
import Main from './pages/Main';
import Login from './pages/Login';
import Flow from './pages/Flow';
import NotFound from './pages/NotFound';
import AuthContext from './context/AuthContext';
import CreateEquipment from './pages/Equipment/CreateEquipment/CreateEquipment';
import ListEquipment from './pages/Equipment/ListEquipment/ListEquipment';
import EditEquipment from './pages/Equipment/EditEquipment/EditEquipment';
import ListingServicesPerformed from './pages/ListingServicesPerformed/ListingServicesPerformed';

const Routing = () => {
  return (
    <Routes>
      <Route element={ <Login /> } path="/login" exact />
      <Route element={ <NotFound /> } path="*" exact />
      <Route path="/" exact element={
        <ProtectedRoutes>
          <Main />
        </ProtectedRoutes>
      } />
      <Route path="/maintenanceflow" exact element={
        <ProtectedRoutes>
          <Flow />
        </ProtectedRoutes>
      } />
      <Route path="/equipments" exact element={
        <ProtectedRoutes>
          <ListEquipment />
        </ProtectedRoutes>
      } />
      <Route path="/equipment/:id" exact element={
        <ProtectedRoutes>
          <EditEquipment />
        </ProtectedRoutes>
      } />
      <Route path="/equipment" exact element={
        <ProtectedRoutes>
          <CreateEquipment />
        </ProtectedRoutes>
      } />
      <Route path="/services" exact element={
        <ProtectedRoutes>
          <ListingServicesPerformed />
        </ProtectedRoutes>
      } />
    </Routes>
  )
}

export default Routing;