import api from "./api";

export const createEquipment = async (equipament) => {
    try {
        const response = await api.post("/machines", equipament);

        return (response.status === 200) ? response : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}